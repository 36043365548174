<template>
  <b-card
    class="ecommerce-card"
    :border-variant="item.isInstalled ? item.isActive ? 'success' : 'danger' : null"
    no-body
  >
    <!-- Product Image -->
    <div class="item-img">
      <b-link>
        <b-img
          :src="CompaniesLogos[item.provider.code]"
          :alt="`${item.name}`"
          class="px-50"
        />
      </b-link>
    </div>

    <!-- Product Details: Card Body -->
    <b-card-body>
      <div class="item-name">
        <h6 class="mb-0">
          <b-link class="text-body">
            <span>{{ item.name_en }} - </span><span class="font-arabic">{{ item.name_ar }}</span>
          </b-link>
        </h6>
        <span
          v-if="item.provider.name"
          class="item-company"
        >{{ $t('By') }} <b-link
          class="company-name"
          :href="item.provider.url"
          target="_blank"
        >{{ item.provider.name }}</b-link></span>
        <span
          v-else-if="item.providerCode"
          class="item-company"
        ><b-link
          href="#"
          class="company-name"
        >{{ $t(`modules.payment_methods.providers.${item.providerCode}`) }}</b-link></span>

        <b-alert />
        <b-alert
          v-if="warningAlert"
          variant="warning"
          class="mt-50"
          show
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="TriangleIcon"
            />
            <span class="ml-25">{{ warningAlert }}</span>
          </div>
        </b-alert>
      </div>
      <div class="mt-auto">
        <div
          v-if="item.provider.hasLimitedSupport"
        >
          <span class="text-secondary">{{ item.provider.supportedCountriesCount }} Supported countries</span>
          <span> - </span>
          <span
            v-if="item.provider.isCountrySupported"
            class="text-success"
          >{{ 'Your country' }} Supported</span>
          <span
            v-else
            class="text-warning"
          >{{ 'Your country' }} Not Supported</span>
        </div>
        <!--        <span-->
        <!--          v-if="item.hasActiveZonesInfo"-->
        <!--          class="text-secondary"-->
        <!--        >-->
        <!--          <material-icon-->
        <!--            icon="request_quote"-->
        <!--            size="20"-->
        <!--          />-->
        <!--          <span v-t="`modules.payment_methods._list.payment_rates`" />: {{ item.activeZonesCount }}-->
        <!--        </span>-->
        <!--        <div-->

        <!--          v-if="item.hasActiveZonesInfo"-->
        <!--          class="pt-1"-->
        <!--        >-->
        <!--          <span-->
        <!--            v-if="item.isCodAllowed"-->
        <!--            class="delivery-date text-success"-->
        <!--          >-->
        <!--            <feather-icon-->
        <!--              icon="CheckIcon"-->
        <!--              size="20"-->
        <!--            />-->
        <!--            <span v-t="`modules.payment_methods._list.cod_is_allowed`" />-->
        <!--          </span>-->
        <!--          <span-->
        <!--            v-else-->
        <!--            class="delivery-date text-secondary"-->
        <!--          >-->
        <!--            <feather-icon-->
        <!--              icon="XIcon"-->
        <!--              size="20"-->
        <!--            />-->
        <!--            <span v-t="`modules.payment_methods._list.cod_is_not_allowed`" />-->
        <!--          </span>-->
        <!--        </div>-->

        <div
          v-if="item.isInstalled"
          class="mt-50"
        >
          <h5 class="item-price text-success d-block">
            <span v-if="item.fixed_fees || item.ratio_fees">
              {{ $n(item.fixed_fees / 100, 'currency') }} + %{{ item.ratio_fees }}
            </span>
            <span
              v-else-if="!item.provider.name"
              v-t="`Free`"
            />
          </h5>
        </div>

        <div
          v-if="item.provider && item.provider.networks"
          class="mt-50"
        >
          <h5 class="item-price text-secondary d-block">
            <span>{{ $t('modules.payment_methods._list.supported_networks') }}:</span>
            <span>
              <span
                v-for="network in item.provider.networks"
                :key="`network_${network.code}`"
              >
                <b-img
                  v-b-tooltip.hover
                  :src="NetworksLogos[network.code]"
                  :alt="`${network.name}`"
                  :title="`${network.name}`"
                  class="px-50 mt-50"
                  width="100"
                  style="height: 25px; width: auto; max-width: 200px"
                />
              </span>
            </span>
          </h5>
        </div>

        <!--        <div-->
        <!--          class="mt-50"-->
        <!--        >-->
        <!--          <h5 class="item-price text-secondary d-block">-->
        <!--            <span>{{$t('modules.payment_methods._list.transaction_charges')}}:</span>-->
        <!--            <span-->
        <!--              v-t="'modules.payment_methods._messages.no_additional_fees'"-->
        <!--            />-->
        <!--          </h5>-->
        <!--        </div>-->
      </div>
    </b-card-body>

    <!-- Product Options/Actions -->
    <div class="item-options text-center">
      <div class="item-wrapper">
        <div
          v-if="item.isInstalled"
          class="item-cost"
        >
          <!--          <h4 class="item-price">-->
          <!--            {{ $n(12, 'currency') }} + %{{ 1 }}-->
          <!--          </h4>-->
          <p
            class="card-text payment"
          >
            <b-form-checkbox
              :id="`${item.code}_is_active`"
              v-model="item.isActive"
              class="mb-1"
              switch
              :disabled="isLoading"
              @change="val => {updateStatus(item.code, val)}"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>

            </b-form-checkbox>
            <b-badge
              v-if="item.isActive"
              v-t="`Active`"
              pill
              variant="light-success"
            />
            <b-badge
              v-else
              v-t="`Inactive`"
              pill
              variant="light-danger"
            />
          </p>
        </div>
      </div>
      <b-button
        v-if="item.hasEditableSettings"
        v-b-modal.modal-add-new
        variant="outline-primary"
        class="btn-cart move-cart"
        :disabled="isLoading"
        @click="$emit('click-edit', item.code)"
      >
        <feather-icon
          icon="SettingsIcon"
          class="mr-50"
        />
        <span
          v-t="`modules.payment_methods.actions.edit`"
          class="text-nowrap"
        />
      </b-button>
      <b-button
        v-if="item.canUninstall"
        variant="light"
        class="mt-1 remove-wishlist"
        :disabled="isLoading"
        @click="deleteItem(item.code)"
      >
        <feather-icon
          icon="TrashIcon"
          class="mr-50"
        />
        <span v-t="`modules.payment_methods.actions.delete`" />
      </b-button>
      <b-button
        v-if="item.canInstall"
        v-b-modal.modal-add-new
        variant="primary"
        class="btn-cart move-cart"
        :disabled="isLoading"
        @click="$emit('click-add', item)"
      >
        <feather-icon
          icon="PowerIcon"
          class="mr-50"
        />
        <span
          v-t="`modules.payment_methods.actions.enable`"
          class="text-nowrap"
        />
      </b-button>
      <div
        class="item-wrapper"
      >
        <div
          class="item-cost"
        >
          <template
            v-if="item.provider.isComingSoon"
          >
            <p class="card-text">
              <b-badge
                v-t="`Coming Soon`"
                pill
                size="5pt"
                variant="light-secondary"
              />
            </p>
            <b-button
              variant="outline-primary"
              class="btn-cart move-cart"
              href="https://ro0nri20lzv.typeform.com/to/Hkuo5M0s"
              target="_blank"
            >
              <feather-icon
                icon="CheckSquareIcon"
                class="mr-50"
              />
              <span
                v-t="`modules.shipping_methods.actions.request_this_integration`"
                class="text-nowrap"
              />
            </b-button>
          </template>
          <p
            v-if="item.showUnavailableMessage"
            class="card-text"
          >
            <b-badge
              pill
              size="5pt"
              variant="light-secondary"
            >
              Unavailable
            </b-badge>
          </p>
        </div>
      </div>

    </div>
  </b-card>
</template>

<script>
import {
  VBTooltip, BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from 'vue'
import useModelDelete, {
  DEFAULT_ALWAYS_CALLBACK,
  DEFAULT_ON_APPROVE_CALLBACK, DEFAULT_ON_FAILURE_CALLBACK,
  DEFAULT_ON_SUCCESS_CALLBACK,
} from '@/views/models/common/useModelDelete'
import paymentMethodStoreModule from '@/views/models/payment_methods/paymentMethodStoreModule'

export const CompaniesLogos = {
  COD: require('@/assets/images/icons/cash-on-delivery-sm.png'),
  BANK: require('@/assets/images/icons/bank-transfer-sm.png'),
  PAYPAL: require('@/assets/images/logos/payment_gateways/paypal.png'),
  PAYFORT: require('@/assets/images/logos/payment_gateways/payfort.png'),
  TAP: require('@/assets/images/logos/payment_gateways/tap.png'),
  PAYTABS_EG: require('@/assets/images/logos/payment_gateways/paytabs.png'),
  PAYTABS_SA: require('@/assets/images/logos/payment_gateways/paytabs.png'),
  PAYTABS: require('@/assets/images/logos/payment_gateways/paytabs.png'),
  PAYMOB: require('@/assets/images/logos/payment_gateways/paymob.png'),
  KASHIER: require('@/assets/images/logos/payment_gateways/kashier.png'),
  INSTAPAY: require('@/assets/images/logos/payment_networks/instapay.svg'),
}

export const NetworksLogos = {
  VISA_MASTERCARD: require('@/assets/images/logos/payment_networks/visa_mastercard.svg'),
  VISA_MASTERCARD_MEEZA: require('@/assets/images/logos/payment_networks/visa_mastercard_meeza.png'),
  VISA_MASTERCARD_MADA: require('@/assets/images/logos/payment_networks/visa_mastercard_mada.png'),
  AMERICAN_EXPRESS: require('@/assets/images/logos/payment_networks/american_express.svg'),
  APPLE_PAY: require('@/assets/images/logos/payment_networks/apple_pay.svg'),
  KNET: require('@/assets/images/logos/payment_networks/knet.png'),
  MADA: require('@/assets/images/logos/payment_networks/mada.svg'),
  MEEZA: require('@/assets/images/logos/payment_networks/meeza.png'),
  MEEZA_MOBILE: require('@/assets/images/logos/payment_networks/meeza.png'),
  EG_MOB_WALLETS: require('@/assets/images/logos/payment_networks/meeza.png'),
  EG_MOBILE_WALLETS: require('@/assets/images/logos/payment_networks/eg_mob_wallets.png'),
  OMAN_NET: require('@/assets/images/logos/payment_networks/oman_net.png'),
  PAYPAL_CREDIT: require('@/assets/images/logos/payment_networks/paypal_credit.png'),
  SADAD: require('@/assets/images/logos/payment_networks/sadad.png'),
  STC_PAY: require('@/assets/images/logos/payment_networks/stc_pay.svg'),
  FAWRY: require('@/assets/images/logos/payment_networks/fawry.png'),
  AMAN: require('@/assets/images/logos/payment_networks/aman.svg'),
  VALU: require('@/assets/images/logos/payment_networks/valu.svg'),
  INSTAPAY: require('@/assets/images/logos/payment_networks/instapay.svg'),
  ORANGE_CASH: require('@/assets/images/logos/payment_networks/organge_cash.png'),
  NBE_PHONE_CASH: require('@/assets/images/logos/payment_networks/nbe_phonecash.png'),
}

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormCheckbox,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  emits: ['click-add', 'click-edit', 'item-update'],
  props: {
    item: {
      type: Object,
      default: Object,
    },
    warningAlert: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      previousStatus: false,
    }
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'payment_methods'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, paymentMethodStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const isLoading = ref(false)

    const {
      deleteItem,
    } = useModelDelete(
      STORE_MODULE_NAME,
      false,
      () => {
        DEFAULT_ON_APPROVE_CALLBACK()
        isLoading.value = true
      },
      () => {
        DEFAULT_ON_SUCCESS_CALLBACK()
        ctx.emit('item-update')
        // console.log('item-updated-event')
      },
      () => {
        DEFAULT_ON_FAILURE_CALLBACK()
      },
      () => {
        DEFAULT_ALWAYS_CALLBACK()
        isLoading.value = false
      },
    )

    const previousStatus = ref(false)
    const onStatusUpdateSuccess = () => {
      const { item } = props
      previousStatus.value = item.is_active
      isLoading.value = false
    }
    const onStatusUpdateFailure = () => {
      const { item } = props
      item.is_active = previousStatus.value
      isLoading.value = false
    }
    const updateStatus = (itemId, newStatus) => {
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/updateAttribute`, { itemId, attribute: 'is_active', value: newStatus })
        .then(response => {
          onStatusUpdateSuccess()
        })
        .catch(() => {
          onStatusUpdateFailure()
        })
    }
    return {
      CompaniesLogos,
      NetworksLogos,
      isLoading,
      previousStatus,
      deleteItem,
      updateStatus,
    }
  },
}
</script>

<style lang="css">
[dir] .ecommerce-application .checkout-items .ecommerce-card .item-img img {
  width: 150px;
}
</style>
